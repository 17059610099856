<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-accent"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
               <div class="form-body">
                   <div class="row">
                        <div class="col l-6 m-6 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="areaName" @md-selected="getAreaSelected" :md-options="areas" @md-changed="getAreas"  @md-opened="getAreas" :class="{'md-invalid': submitted && $v.entity.areaId.$error }">
                                    <label>Tuyến đường</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.areaName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.areaId.isSelected">Vui lòng chọn tuyến đường</span>
                                </md-autocomplete>
                                <md-button @click="openArea()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm tuyến đường</md-tooltip>
                                </md-button>
                            </div>
                            <div class="form-control">
                                <md-autocomplete v-model="goodsName" @md-selected="getGoodsSelected" :md-options="goods" @md-changed="getGoods"  @md-opened="getGoods" :class="{'md-invalid': submitted && $v.entity.goodsId.$error }">
                                    <label>Hàng hóa</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.goodsName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.goodsId.isSelected">Vui lòng chọn hàng hóa</span>
                                </md-autocomplete>
                                <md-button @click="openGoods()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm hàng hóa</md-tooltip>
                                </md-button>
                            </div>
                            <div class="form-control">
                                <md-autocomplete v-model="unitName" @md-selected="getUnitSelected" :md-options="units" @md-changed="getUnits"  @md-opened="getUnits" :class="{'md-invalid': submitted && $v.entity.unitId.$error }">
                                    <label>Đơn vị</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.unitName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.unitId.isSelected">Vui lòng chọn đơn vị</span>
                                </md-autocomplete>
                                <md-button @click="openUnit()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm đơn vị</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.price.$error }">
                                <label for="price">Đơn giá</label>
                                <md-input name="price" v-model="entity.price"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.price.required">Vui lòng nhập đơn giá</span>
                            </md-field>

                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-datepicker md-immediately v-model="entity.fromDate" :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.fromDate.$error }">
                                        <label>Từ ngày</label>
                                        <span class="md-error" v-if="submitted && !$v.entity.fromDate.required">Vui lòng chọn ngày từ ngày</span>
                                    </md-datepicker>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-datepicker md-immediately v-model="entity.toDate" :md-model-type="String">
                                        <label>Tới ngày</label>
                                    </md-datepicker>
                                </div>
                            </div>

                            <md-field>
                                <label for="note">Ghi chú</label>
                                <md-input name="note" v-model="entity.note" ></md-input>
                            </md-field>

                            <div class="radio-group">
                                <label>Trạng thái</label>
                                <div class="form-control ">
                                    <md-radio v-model="entity.isActive" :value="true">Kích hoạt</md-radio>
                                    <md-radio v-model="entity.isActive" :value="false">Khóa</md-radio>
                                </div>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
        <areaList ref="areaList" @close="closeArea"/>
        <unitList ref="unitList" @close="closeUnit"/>
        <goodsList ref="goodsList" @close="closeGoods"/>
   </div>
</template>
<script>
    import freightService from '../../../api/freightService';
    import areaService from '../../../api/areaService';
    import transportUnitService from '../../../api/transportUnitService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins'; 
    import areaList from '../../../components/popup/_AreaList.vue';
    import unitList from '../../../components/popup/_TransportUnitList.vue';
    import goodsList from '../../../components/popup/_GoodsList.vue';
    import goodsService from '../../../api/goodsService';

    export default ({
        components: {
            areaList,
            unitList,
            goodsList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật bảng cước vận chuyển'
        },
        data() {
            return {
                title: '',
                id: 0,
                submitted: false,
                entity: { id: 0, areaId: 0, goodsId: 0, unitId: 0, price: 0, fromDate: null, toDate: null, note: '', isActive: true },
                areaName: '',
                areas: [],
                unitName: '',
                units: [],
                goodsName: '',
                goods: [],
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật bảng cước vận chuyển';
               this.getById();
            }
            else{
               this.title = 'Thêm mới bảng cước vận chuyển';
               this.entity.fromDate = common.dateNow;
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            //Goods
            closeGoods(item){
                this.goodsName = item.goodsName;
                this.entity.goodsId = item.id;
                this.$refs.goodsList.close();
            },

            getGoodsSelected(val){
                this.entity.goodsId = val.id;
                this.goodsName = val.goodsName;
            },

            openGoods(){
                this.$refs.goodsList.open();
            },

            getGoods(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code:  searchTerm };
                goodsService.getGoodss(search).then((response) => {
                    if(response.statusCode == 200){
                        this.goods = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },


            //Unit
            closeUnit(item){
                this.unitName = item.unitName;
                this.entity.unitId = item.id;
                this.$refs.unitList.close();
            },

            getUnitSelected(val){
                this.entity.unitId = val.id;
                this.unitName = val.unitName;
            },

            openUnit(){
                this.$refs.unitList.open();
            },

            getUnits(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code:  searchTerm };
                transportUnitService.getTransportUnits(search).then((response) => {
                    if(response.statusCode == 200){
                        this.units = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Area
            closeArea(item){
                this.areaName = item.areaName;
                this.entity.areaId = item.id;
                this.$refs.areaList.close();
            },

            getAreaSelected(val){
                this.entity.areaId = val.id;
                this.areaName = val.areaName;
            },

            openArea(){
                this.$refs.areaList.open();
            },

            getAreas(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code:  searchTerm };
                areaService.getAreas(search).then((response) => {
                    if(response.statusCode == 200){
                        this.areas = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                freightService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                freightService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/freight');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                freightService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.areaName = response.data.area.areaName;
                        this.goodsName = response.data.goods.goodsName;
                        this.unitName = response.data.unit.unitName;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            back(){
                this.$router.push('/freight');
            }
        },
        watch: { 
            areaName: function (val) { 
                if(val == ''){
                    this.entity.areaId = 0;
                }
            },
            goodsName: function (val) { 
                if(val == ''){
                    this.entity.goodsId = 0;
                }
            },
            unitName: function (val) { 
                if(val == ''){
                    this.entity.unitId = 0;
                }
            },
        },
        validations: {
            entity: {
                areaId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                goodsId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                unitId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                price: { required },
                fromDate: { required },
            }
        }
     })

</script>
